import React from 'react';
import emailjs from 'emailjs-com';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: 'linear-gradient(0deg, rgba(39, 1, 1, 0.9), rgba(39, 1, 1, 0.9)), url(ContactBackground.jpg)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        color: 'white',
        },
    heading: {
        fontFamily: 'Varela Round',
        fontSize: '3rem',
        letterSpacing: '1rem',
          [theme.breakpoints.down('sm')]: {
              fontSize: '2rem',
              letterSpacing: '.8rem'
          }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        textAlign: 'left',
        width: '70vw',
        fontFamily: 'Roboto',
        fontSize: '2rem',
        padding: '2rem',
    },
    name: {
        padding: '.5rem',
        letterSpacing: '.3rem'
    },
    nameBox: {
        width: '30vw',
        height: '3vh',
          [theme.breakpoints.down('md')]: {
            width: '40vw',
            height: '5vh',
          },
          [theme.breakpoints.down('sm')]: {
              width: '60vw',
              height: '4vh'
          }
    },
    email: {
        padding: '.5rem',
        letterSpacing: '.3rem'

    },
    emailBox: {
        width: '30vw',
        height: '3vh',
          [theme.breakpoints.down('md')]: {
            width: '40vw',
            height: '5vh',
          },
          [theme.breakpoints.down('sm')]: {
              width: '60vw',
              height: '4vh'
          }
    },
    message: {
        padding: '.5rem',
        letterSpacing: '.2rem'

    },
    messageBox: {
        width: '30vw',
        height: '20vh',
          [theme.breakpoints.down('md')]: {
            width: '40vw',
          },
          [theme.breakpoints.down('sm')]: {
              width: '60vw',
          }
    },
    button: {
        fontFamily: 'Roboto',
        fontSize: '1rem',
        letterSpacing: '.2rem',
        textTransform: 'uppercase',
        margin: '1rem',
        width: '10rem',
        height: '2.5rem',
        backgroundColor: 'white',
        borderRadius: '2rem',
        border: 'none',
          '&:hover': {
              backgroundColor: '#555555',
              cursor: 'pointer'
          },
          [theme.breakpoints.down('md')]: {
              marginTop: '1.5rem',
          }
    }
}))


export default function Form() {
  const classes = useStyles();
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_6hpp8dg', 'template_w7cp0xd', e.target, 'user_kxmBVZbnb4V9qBmabbQuk')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  }

  return (
      <div className={classes.root}>
          <h2 className={classes.heading}>CONTACT</h2>
        <form className={classes.form} onSubmit={sendEmail}>
          <label className={classes.name}>Name</label>
            <input type="text" name="user_name" className={classes.nameBox} />
          <label className={classes.email}>Email</label>
            <input type="email" name="user_email" className={classes.emailBox} />
          <label className={classes.message}>Message</label>
            <textarea name="message" className={classes.messageBox} />
            <input type="submit" value="Send" className={classes.button} />
    </form>
    </div>
  );
}