import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        color: 'white',
        fontFamily: 'Roboto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '5rem'
    },
    text: {
        fontSize: '2rem',
        letterSpacing: '.3rem',
        lineHeight: '2.7rem',
        textAlign: 'left',
        width: '60vw',
          [theme.breakpoints.down('md')]: {
              fontSize: '1.3rem',
              width: '70vw',
              lineHeight: '2rem'
          }
    }
}));

export default function EducationDesc() {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <p className={classes.text}>Drum Church was founded to create space for education 
            that is accessible, safe, and focused on building critical thinking and character. 
            Building up individuals who can contribute to the betterement of their lives, culture, and community.  
            </p>
        </div>
    )
}