import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom'




const useStyles = makeStyles((theme) => ({
  root: {
      fontFamily: 'Roboto',
      backgroundColor: '#555555',

  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  nav: {
      textDecoration: 'none',
      color: 'white',
      fontSize: '1.5rem',
      '&:hover': {
          color: 'red'
      },
  },
  mobileNav: {
      textDecoration: 'none',
      color: 'black',
      '&:hover': {
          color: 'red',
      },
  },

  logo: {
     width: '160px',
     
   
  },

  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function PrimarySearchAppBar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Link to="/" className={classes.mobileNav}>
            HOME
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to="/About" className={classes.mobileNav}>
            ABOUT
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to="/Music" className={classes.mobileNav}>
            MUSIC
        </Link>
      </MenuItem>
      <MenuItem>
       <Link to="/Contact" className={classes.mobileNav}>
           CONTACT
       </Link>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar 
        position="static"
        className={classes.root}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
              <Link to="/">
              <img src="DrumChurchLogo.PNG" alt="" className={classes.logo} />
              </Link>
          </IconButton>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
          <IconButton aria-label="Home Page" color="inherit">
                <Link to="/" className={classes.nav}>
                HOME
                </Link>
            </IconButton>
            <IconButton aria-label="About Page" color="inherit">
                <Link to="/About" className={classes.nav}>
                ABOUT
                </Link>
            </IconButton>
            <IconButton aria-label="Music Page" color="inherit">
            <Link to="/Music" className={classes.nav}>
                MUSIC
                </Link>
            </IconButton>
            <IconButton aria-label="Contact Page" color="inherit">
                <Link to="/Contact" className={classes.nav}>
              CONTACT
              </Link>
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
