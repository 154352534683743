import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'linear-gradient(0deg, rgba(39, 1, 1, 0.7), rgba(39, 1, 1, 0.7)), url(drumChurchBio.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        fontFamily: 'Roboto',
        color: 'white',
        height: '80vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'left',
          [theme.breakpoints.down('sm')]: {
              backgroundPosition: 'right center'
          }
    },
    quote: {
        fontSize: '2rem',
        width: '60vw',
        letterSpacing: '.5rem',
          [theme.breakpoints.down('sm')]: {
              fontSize: '1.3rem',
              width: '70vw',
              letterSpacing: '.5rem'
          }
    },
    cite: {
        letterSpacing: '.7rem'
    }
}));

export default function Education() {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <blockquote className={classes.quote} cite="https://www.keepinspiring.me/martin-luther-king-jr-quotes/">
                <p>“The function of education is to teach one to think intensively and to think critically. Intelligence plus character – 
                    that is the goal of true education.” </p>
                <figcaption>
                <cite className={classes.cite}>– Martin Luther King Jr.</cite>
                </figcaption>
                
            </blockquote>
        </div>
    )
}