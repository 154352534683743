import React from 'react'
import { Paper } from '@material-ui/core'
import Carousel from 'react-material-ui-carousel'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100vw',
        height: '50vh',
        background: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    carousel: {
        margin: '0px',
        padding: '0px',
        marginBottom: '10rem',
        background: 'transparent',
    },
    img: {
       width: '30vw',
       maxHeight: '40vh',
       padding: '0px',
       margin: '0px',
       borderRadius: '2px',
       background: 'transparent'
    }
}));

export default function Gallery(props) {
    const classes = useStyles();

    const items = [
        {
            image: '../drumChurchBio.jpg',
        },
        {
            image: "../DrumChurchEnrich.jpg",
        },
        {
            image: "../Gallery3.jpeg",
        },
        {
            image: '../drumChurchBio.jpg'
        }
    ]
    return(
        <Carousel className={classes.carousel}>{items.map( (item, i) => <Item key={i} item={item} />)}</Carousel>

    )
}

function Item(props) {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <img className={classes.img} src={props.item.image} alt="" />
            <p>{props.item.description}</p>

           
        </Paper>
    )
}