import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import '@material-ui/icons'
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: '#555555',
        fontFamily: 'Varela Round',
        color: 'white',
        height: '40vh',
        boxShadow: '0px 0 10px rgba(0, 0, 0, 0.8)',
        flexWrap: 'wrap',
        flexShrink: '3',
        flexBasis: 'auto',
        flexGrow: '1',
        fontSize: '10px',
          [theme.breakpoints.down('md')]: {
              height: '70vh',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
          },
          margin: '0px',
          padding: '0px'
    },
    heading: {
        letterSpacing: '15px',
        textTransform: 'uppercase',  
        fontSize: '2rem'  
    },
    contact: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        color: 'white',
          [theme.breakpoints.down('md')]: {
              textAlign: 'center',
              paddingTop: '2rem',
              paddingBottom: '2rem'
          },
    },
    links: {
        textDecoration: 'none',
        color: 'white',
        fontSize: '1rem',
        letterSpacing: '5px',
        lineHeight: '25px',
          [theme.breakpoints.down('sm')]: {
            fontSize: '.8rem'
          },
    '&:hover': {
        color: 'red',
    },
    },
    follow: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
          [theme.breakpoints.down('md')]: {
            paddingTop: '2rem',
            paddingBottom: '2rem'
          },
        
    },
    iconGroup: {
        display: 'flex',
        justifyContent: 'space-around',
        marginRight: '1rem'
    },
    icon: {
        color: 'white',
        fontSize: '4.23rem',
      
        '&:hover': {
            color: 'red',
        },
    },
   
    

 }));


export default function Footer() {
const classes = useStyles();

    return(
        <div className={classes.root} id="Contact">
            <div className={classes.contact}>
                <h2 className={classes.heading}>Contact</h2>
                <a className={classes.links} href="tel:+14088377746">(408)837-7746</a>
                <a className={classes.links} href="tel:+18888589891;101">1(888)858-9891 ext 101</a>
                <a className={classes.links} href="mailto:shookblack@drumchurchpdx.com">shookblack@drumchurchpdx.com</a>
            </div>
            <div className={classes.follow}>
                <h2 className={classes.heading}>Follow</h2>
                <div className={classes.iconGroup}>
                  <a 
                    target="_blank"
                    rel="noreferrer"
                    className={classes.iconLink} 
                    href="https://www.facebook.com/DrumChurchLife">
                        <FacebookIcon className={classes.icon} /></a>
                  <a 
                    target="_blank"
                    rel="noreferrer"
                    className={classes.iconLink} 
                    href="https://www.instagram.com/drumchurchpdx/">
                        <InstagramIcon className={classes.icon} /></a>
                </div>
            </div>
        </div>
    )
}