import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        color: 'white',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0px',
        padding: '0px'
        },
    header: {
        fontFamily: 'Varela Round',
        fontSize: '3rem',
        letterSpacing: '1rem',
          [theme.breakpoints.down('sm')]: {
              fontSize: '2rem',
              marginLeft: '1rem'
          }
    },
    container: {
       display: 'flex',
       width: '100vw',
       justifyContent: 'center',
       alignItems: 'center',
       marginBottom: '4rem',
       textAlign: 'left',
       overflow: 'scroll',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '70vw',
        textAlign: 'left',
        },
    image: {
        width: '500px',
        height: 'auto',
        borderRadius: '.5rem',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
          [theme.breakpoints.down('sm')]: {
              width: '300px',
          }
    },
    text: {
        fontFamily: 'Varela Round',
        fontSize: '2rem',
        letterSpacing: '.5rem',
        textAlign: 'center'
    },
    bio: {
        fontFamily: 'Roboto',
        fontSize: '2rem',
        height: '30vh',
        letterSpacing: '.3rem',
        lineHeight: '2.7rem',
          [theme.breakpoints.down('sm')]: {
              lineHeight: '2rem'
          }
    },
    bioText: {
        [theme.breakpoints.down('md')]: {
          fontSize: '1.3rem',
          letterSpacing: '.3rem',
          lineHeight: '2rem'
        }
    }
  
}));

export default function Founder() {
    const classes = useStyles();

    return(
        <div className={classes.root}>
          <h2 className={classes.header}>MEET SHOOK</h2>
            <div className={classes.container}>
              <div className={classes.content}>
                <img className={classes.image} src="DrumChurchEmpower.jpg" alt="" />
                <div className={classes.bio}>
                <p className={classes.text}>OUR FOUNDER</p>
                <p className={classes.bioText}>Im most commonly known as Shook Black. 
                I am a 34 year old, Black Portland native and international musician, 
                residing and playing in the Portland music scene for 14 years now. 
                I grew up in a low to middle class religious family playing drums in church at age 4. 
                Had my first professional gig at The Portland Art Museum at 14. 
                But sadly didn’t obtain a professional drumset until age 18. 
                Luckily I grew up religious and it kept me close to drums and music. 
                Nevertheless, a few years after I left Portland, decided to further my musical education 
                and enrolled into MI (musicians institute) at 21, 
                where I took my first professional lesson. 
                I shortly returned to Portland and started my profession as a working, 
                gigging, professional drummer. 
                Prior to COVID, I had the opportunity to perform all over Oregon and the west coast, 
                nationally and internationally with great people of great talent, 
                varying in age and skill set. 
                Including Grammy Award Winning, Thara Memory and AMP, 
                Tahira Memory, Jared Lawson, Saeeda Wright, 
                Farnell Newton, Linda Hornbuckle, Chris Brown, Greg Goebel,
                Damian Erskine, Noah Simpson, Charlie Brown and Cary Miga and so many more. 
                In the 20 year span of playing over 20 styles of music with 15 bands regularly, 
                Ive played at most of Portlands premier or most common music venues and events, 
                including Jimmy Mak’s, The Brasserie, The Crystal Ballroom, The Roseland, 
                The Aladdin, The Alberta Rose Theatre, The Schnitzer, The Convention Center, 
                a monthly residency at The 1905, the Coliseum and the MODA Center to name a few. 
                I also have performed and Headlined the Blues Festival, Rose Festival, 
                PDX Jazz Festival and more. And with Lori Henriques, 
                A Grammy Award Winning Children’s Author. 
                As well as a few collaborations  with urban collective development group, 
                YGB to perform at P.I.C.A. 2016, thanks to the collaboration. 
                Ive always wanted to provide a safe space and help other artists 
                and creatives express their talent or skill to its fullest potential. 
                That’s why I started teaching drums and founded Drum Church. 
                A non-profit organization committed to Empowering, 
                Educating and Treating African American and BIPOC underserved communities locally, 
                nationally and worldwide, in its relationship to music local and abroad, 
                social justice, mental, spiritual and economic health. 
                Take Bryce Olson for instance. 
                Also a Portland native, The Global Marketing Director at Intel 
                and a stage 4 cancer survivor. 
                I and a few others collaborated with him on his very first album 
                about his experience with break through technology that saved his life 
                and has saved countless others. Or Shelley Rudolph who introduced me to Molly, 
                an 8 year old with pancreatic cancer who’s only wish was to record a single 
                so she could hear her voice professionally recorded. Or Career Day at Scott Elementary
                because my friends a school teacher there. Giving back and establishing community, 
                encouraging the creatives and curating opportunity for others to be 
                their creative selves is a necessity in my mind and so, I do just that. 
                To inspire community. To inspire change. What about the kids who won’t have that same experience 
                and be as fortunate as I even was? What if their parents are never able to afford it? 
                Or  discouraged to continue their true passion because, 
                “it’s a hobby, not a job,” like I was told. 
                The government has long shutdown the funding for the Arts, 
                if you aren’t fortunate to have the finances to afford the cost of what it means to “drum” 
                you are surely left behind and discouraged. My goal is to provide mental, physical, 
                economic, social and spiritual therapy to all who are in need of it. 
                I believe Drum Church, when it’s all said and done, 
                will be a full wellness creative center for all who wish to participate 
                NOT just the less fortunate. I believe this is a start to bringing the arts 
                and creative expression back into our collective communities and individual homes. 
</p></div>
              </div>
            </div>

        </div>
    )
}