import './App.css';
import PrimarySearchAppBar from './Components/Navbar'
import Footer from './Components/Footer'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomePage from './Pages/Home'
import AboutPage from './Pages/About'
import MusicPage from './Pages/Music'
import ContactPage from './Pages/Contact'


function App() {
  return (
    <Router>
    <div className="App">
      <PrimarySearchAppBar />
        <Switch>
          <Route path='/' exact component={HomePage} />
          <Route path='/About' component={AboutPage} />
          <Route path='/Music' component={MusicPage} />
          <Route path='/Contact' component={ContactPage} />

        </Switch>
     <Footer />
    </div>
    </Router>
  );
}

export default App;
