import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import landingPageVideo from '../Assets/ShookBlack.mov'

const useStyles = makeStyles((theme) => ({
    root: {
        color: 'white',
        background: 'black',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
          [theme.breakpoints.down('sm')]: {
            height: '60vh'
          },
    },
    video: { 
        margin: '0px',
        padding: '0px',
        borderRadius: '4px',
        boxShadow: '4px 4px 15px rgba(255, 255, 255, 0.5)',
        width: '100vw',
        height: '100vh',
         [theme.breakpoints.down('sm')]: {
           width: '300px',
           height: 'auto'
         },
       
    },
  
}))

export default function HomeVideo() {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <video className={classes.video} controls autoPlay muted fullscreen="true">
                <source src={landingPageVideo} type="video/mp4"></source>
            </video>
        </div>
    )
}