import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Gallery from '../Components/Gallery'


const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0',
        padding: '0',
        background: 'url(MusicBackground.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '10px',
        textAlign: 'center',
        marginBottom: '',
          [theme.breakpoints.down('sm')]: {
              backgroundAttachment: 'scroll',
              backgroundPosition: 'center',
          }
    },
    header: {
        color: 'white',
        paddingTop: '3rem',
        fontFamily: 'Varela Round',
        fontSize: '5rem',
        paddingLeft: '2rem',
        letterSpacing: '3rem',
         [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
            letterSpacing: '2.5rem',
            paddingLeft: '2rem'
         },
    },
    video: {
        margin: '6rem 0rem',
        borderRadius: '5px',
        boxShadow: '1px 1px 5px rgba(255, 255, 255, 0.5)',
          [theme.breakpoints.down('sm')]: {
            width: 'auto',
            height: 'auto',
          },
    },
    videoBottom: {
        marginBottom: '12rem'
    }
}));

export default function Music() {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <h2 className={classes.header}>MUSIC</h2>
            <iframe 
                title="Hey"
                className={classes.video}
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/uRpFdAQKuSM" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
            <iframe 
                title="flashingLights"
                className={classes.video}
                width="560"
                height="315" 
                src="https://www.youtube.com/embed/kzMHacpYBJ8" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
            <iframe 
                title="nanã"
                className={classes.video}
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/kzMHacpYBJ8" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
            <Gallery />
        </div>
    )
}