import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import '../App.css'
import { Link } from 'react-router-dom'
import Fab from '@material-ui/core/Fab'


const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
      width: '100vw',
      background: 'linear-gradient(0deg, rgba(253, 1, 3, 0.4), rgba(253, 1, 3, 0.4)), url(DrumChurchLandingPage.jpg)',
      backgroundSize: 'cover',
      boxSizing: 'border-box',
       
       [theme.breakpoints.down('md')]: {
        backgroundAttachment: 'scroll',
        backgroundPosition: 'center right',
        backgroundClip: 'border-box',
        backgroundSize: 'auto',
        margin: '0px',
        padding: '0px',
        height: '75vh',
       },
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      fontFamily: 'Varela Round',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '10px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '75%',
      textAlign: 'left',
      width: '70vw',
      fontSize: '3rem',
       [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
        justifyContent: 'flex-end'
       },
      letterSpacing: '1rem',
      textShadow: '0px 0px 1px rgba(0, 0, 0, 60%)',
    },
    header: {
        paddingBottom: '1rem',
        margin: '0px',
        marginLeft: '-7px',
         [theme.breakpoints.down('md')]: {
             marginLeft: '-3px'
         }
    },
    desc: {
        fontFamily: 'Roboto',
        fontSize: '2rem',
        textTransform: 'uppercase',
        lineHeight: '0',
        letterSpacing: '1rem',
          [theme.breakpoints.down('sm')]: {
              fontSize: '1.7rem',
              letterSpacing: '.8rem'
          }
    },
    button: {
        width: '20%',
        letterSpacing: '.2rem',
        textDecoration: 'none',
        color: 'black',
        fontFamily: 'Roboto',

         [theme.breakpoints.down('md')]: {
            width: '40vw',
         },
        marginTop: '3rem',
        '&:hover': {
            backgroundColor: '#555555',
        },
         
    },
    link: {
        textDecoration: 'none',
        color: 'black'
    }
 
}));

export default function Hero() {
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <div className={classes.container}>
            <h1 className={classes.header}>DRUM CHURCH</h1>
            <p className={classes.desc}>Educate</p>
            <p className={classes.desc}>Empower</p>
            <p className={classes.desc}>Enrich</p>
            <Link
                to='/Music'
                className={classes.link}>
                    <Fab
                      className={classes.button}
                      variant="extended"
                      aria-label="see work">                     
                        View Work
                    </Fab>
            </Link>
                    
          
            </div>
        </div>
    )
}
