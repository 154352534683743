import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TeachingVideo from '../Assets/DrumChuchTeaching.mov'

const useStyles = makeStyles((theme) => ({
    root: {
        color: 'white',
        margin: '0rem',
        padding: '0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw'
    },
    header: {
        fontSize: '3rem',
        letterSpacing: '1rem',
        margin: '4rem',
        fontFamily: 'Varela Round',
          [theme.breakpoints.down('sm')]: {
              fontSize: '2rem'
          }
        },
    container: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'left',
        width: '100vw'
    },
    flexBox: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '70vw',
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
    },
    text: {
        fontFamily: 'Roboto',
        fontSize: '2rem',
        letterSpacing: '.3rem',
        display: 'flex',
        alignItems: 'center',
        width: '30vw',
        lineHeight: '2.7rem',
          [theme.breakpoints.down('md')]: {
              width: '70vw',
              fontSize: '1.3rem',
              lineHeight: '2rem'
          }
    },
    video: {
        margin: '0px',
        padding: '0px',
        width: '560px',
        height: '315px',
        borderRadius: '5px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
        alignSelf: 'center',
          [theme.breakpoints.down('sm')]: {
              width: '100%',
              height: 'auto'
          }
    },
}));

export default function Empower() {
    const classes = useStyles();

    return(
        <div className={classes.root}>
          <h2 className={classes.header}>EMPOWER</h2>
            <div className={classes.container}> 
              <div className={classes.flexBox}>
                <p className={classes.text}>Creating opportunities for our youth to learn from world class professionals
                                            inspires self motivation to make their dreams an accessible reality. </p>
                <video className={classes.video} controls autoPlay muted fullscreen="true">
                  <source src={TeachingVideo} type="video/mp4"></source>
                </video>
              </div>           
            </div>
        </div>
    )
}