import React from 'react'
import AboutHero from '../Components/AboutHero.js'
import { makeStyles } from '@material-ui/core/styles'
import EducationDesc from '../Components/EducationDesc'
import Empower from '../Components/Empower'
import Enrich from '../Components/Enrich'
import Founder from '../Components/Founder'

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#270101',
        margin: '0px',
        padding: '0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
}))

export default function AboutPage() {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <AboutHero />
            <EducationDesc />
            <Empower />
            <Enrich />
            <Founder />
        </div>
    )
}