import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        color: 'white',
        margin: '4rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
     
    },
   
    header: {
        fontFamily: 'Varela Round',
        fontSize: '3rem',
        letterSpacing: '1rem',
          [theme.breakpoints.down('sm')]: {
              fontSize: '2rem',
              letterSpacing: '1.2rem',
          }
    },
    text: {
        textAlign: 'left',
        fontFamily: 'Roboto',
        fontSize: '2rem',
        letterSpacing: '.3rem',
        lineHeight: '2.7rem',
        width: '70vw',
          [theme.breakpoints.down('md')]: {
              width: '70vw',
              fontSize: '1.3rem',
              lineHeight: '2rem'
          }
    }
}));

export default function Enrich() {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <h2 className={classes.header}>ENRICH</h2>
            <p className={classes.text}>Drum Church is committed to adding value to communities through spiritual, artistic, and
                                        and cultural enrichment of the indiviaul and empowering community building.</p>
                                  
        </div>
    )
}