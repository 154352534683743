import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Form from '../Components/Form'

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: 'black',
        height: '100vh',
        margin: '0px',
        padding: '0px'
    }
}));

export default function Contact() {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Form />
        </div>
    )
}