import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        backgroundColor: '#FD0103',
        background: 'url(DrumChurchLogoBackground.jpg)',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        [theme.breakpoints.down('md')]: {
            backgroundAttachment: 'scroll'
        },
    },
    container: {
        margin: '0px',
        padding: '3rem',
        width: '70vw',
        height: '60vh',
        textAlign: 'left',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        backdropFilter: 'blur(20px) saturate(160%) contrast(45%) brightness(140%)',
        position: 'relative',
        borderRadius: '15px',
        boxSizing: 'border-box',
          [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
            width: '80vw',
            padding: '2rem',
            overflow: 'scroll',
            height: '45vh',
          },
    },
    header: {
        fontFamily: 'Varela Round',
        margin: '0px',
        padding: '0px',
        fontSize: '3rem',
         [theme.breakpoints.down('md')]: {
            fontSize: '1.5rem',
            lineHeight: '2rem'
         },
        letterSpacing: '1rem'
    },
    text: {
        fontFamily: 'Roboto',
        fontSize: '2rem',
        letterSpacing: '.3rem',
        lineHeight: '3rem',
          [theme.breakpoints.down('md')]: {
              fontSize: '1.5rem',
              lineHeight: '2rem'
          },
    },
}))

export default function HomeBio() {
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <div className={classes.container}>
              <div className={classes.glass}>
                 <h2 className={classes.header}>OUR MISSION</h2>
                 <p className={classes.text}>Drum Church is committed to Empowering, 
                    Educating African American and POC communities locally, 
                    nationally & the world at large, in its past, 
                    present and future relationship to music local & abroad, 
                    social justice, mental, spiritual and economic health</p>
               </div>
            </div>
        </div>
    )
}