import React from 'react'
import Hero from '../Components/Hero'
import HomeBio from '../Components/HomeBio'
import HomeVideo from '../Components/HomeVideo'

export default function HomePage() {
    return(
        <div className="Home">
            <Hero />
            <HomeBio />
            <HomeVideo />
        </div>
    )
} 